import { HiOutlineBanknotes } from "react-icons/hi2";
import { RiCoinsLine } from "react-icons/ri";
import { IoReceiptOutline } from "react-icons/io5";
import { GoHeart } from "react-icons/go";
import { IoSettingsOutline } from "react-icons/io5";
import { AiOutlineWallet } from "react-icons/ai";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { motion } from "framer-motion";
import { useAuth } from "../../hooks/useAuth";
import { TbPassword } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { formatToLocale } from "../../tools/formatNumber";
import copy from "../../assets/images/copy.svg";
import refresh from "../../assets/images/refresh.svg";
import { toast } from "sonner";
import { useEffect, useState } from "react";
import { IoTicketOutline } from "react-icons/io5";
import { MdCancel } from "react-icons/md";
import { BiSolidCopy } from "react-icons/bi";
import { Link } from "react-router-dom";

import { MdOutlineHistory } from "react-icons/md";

const Profile = ({ setShowProfile }) => {
  const {
    auth,
    logout,
    isLoading,
    itIsLoading,
    getBalance,
    getCommissionBalance,
    commissionBalance,
  } = useAuth();

  const navigate = useNavigate();

  const handleCopy = () => {
    navigator.clipboard.writeText(auth.virtual_acct).then(() => {
      toast.success("Copied to clipboard!");
    });
  };

  useEffect(() => {
    if (!auth.wallet_balance || auth.wallet_balance === "") {
      getBalance();
    }
  }, []);

  useEffect(() => {
    if (!commissionBalance || commissionBalance == "") {
      getCommissionBalance();
    }
  }, []);

  return (
    <div
      className="fixed top-0 left-0 w-screen h-screen z-[10000] bg-[#1A1C1B80] flex items-center justify-center md:justify-end md:pr-[80px]"
      onClick={() => {
        setShowProfile(false);
      }}
    >
      <motion.div
        animate={{ y: [-100, 40, -30, 0] }}
        transition={{ duration: 0.5 }}
        className="bg-[white] border border-dGrey1 w-[80%] max-w-[410px] rounded-[8px] py-[24px] px-[24px] z-[10000]"
        style={{
          boxShadow: "0px 4px 70px 0px #818B9C33",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="flex justify-end text-darkGreen1">
          <MdCancel
            className="w-20 h-20"
            onClick={() => {
              setShowProfile(false);
            }}
          />
        </div>
        <div className="flex gap-[16px]">
          <div className="flex flex-col">
            <div className="text-[20px] leading-[28px]">{`${auth.firstname} ${auth.lastname}`}</div>
            <div className="text-[16px] leading-[25.6px] text-grey1 capitalize">
              {auth.user_type.endsWith("s")
                ? auth.user_type.slice(0, -1)
                : auth.user_type}
            </div>

            <div className="relative">
              {/* <div className="m-auto absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] bg-darkGreen1 w-96 px-6  py-1 rounded-full text-[white] flex items-center justify-center gilroy-medium text-sm gap-4"> */}
              <div className="whitespace-nowrap select-none fs-6 pt-2 ">
                {`${auth.virtual_acct} | Taj_Pinspay`}
                <span>
                  <BiSolidCopy
                    className="w-26 h-26 cursor-pointer  text-darkGreen1 mx-10 pt-4"
                    onClick={handleCopy}
                  />
                  {/* <img
                    src={copy}
                    alt="copy-button"
                    className="cursor-pointer"
                    onClick={handleCopy}
                  /> */}
                </span>
              </div>
              <div>
                <Link
                  className="hover:!text-darkGreen2 text-darkGreen1 text-sm"
                  to={"/settings/Wallet-history"}
                >
                  . Wallet history
                </Link>
              </div>
              {/* </div> */}
              <hr className="border border-dGrey1 my-[24px]" />
            </div>
          </div>
        </div>

        <hr className="border border-dGrey1 mt-[17px] mb-[24px]" />
        <div className="flex gap-[12px] flex-col">
          <div className="text-[14px] leading-[22.4px] text-grey1">Wallet</div>
          <div className="flex flex-col gap-2">
            <div className="flex justify-between items-center">
              <div className="flex gap-3">
                <HiOutlineBanknotes className="size-[24px]" />
                <div className="text-[16px] leading-[25.6px] text-grey1 ml-2">
                  Balance
                </div>
              </div>
              <div className="text-[16px] leading-[25.6px] lg:text-xl whitespace-nowrap flex items-center gap-2 lg:gap-5">
                <img
                  src={refresh}
                  alt=""
                  className={`cursor-pointer ${isLoading && "animate-spin"}`}
                  onClick={getBalance}
                />
                &#8358;{formatToLocale(auth.wallet_balance)}
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex gap-3">
                <AiOutlineWallet className="size-[24px]" />
                <div className="text-[16px] leading-[25.6px] text-grey1 ml-2">
                  Commission
                </div>
              </div>
              <div className="text-[16px] leading-[25.6px] lg:text-xl whitespace-nowrap flex items-center gap-2 lg:gap-5">
                <img
                  src={refresh}
                  alt=""
                  className={`cursor-pointer ${itIsLoading && "animate-spin"}`}
                  onClick={getCommissionBalance}
                />
                &#8358;{formatToLocale(commissionBalance)}
              </div>
            </div>
            {/* <div className="flex justify-between items-center">
              <div className="flex gap-3">
                <AiOutlineWallet className="size-[24px]" />
                <div className="text-[16px] leading-[25.6px] text-grey1">
                  Commission Balance
                </div>
              </div>
              <div className="text-[16px] leading-[25.6px] ">
              <img
                  src={refresh}
                  alt=""
                  className={`cursor-pointer ${itIsLoading && "animate-spin"}`}
                  onClick={getCommissionBalance}
                />
                &#8358;{formatToLocale(commissionBalance)} </div>
            </div> */}
            {/* <div className="flex justify-between items-center">
              <div className="flex gap-3">
                <RiCoinsLine className="size-[24px]" />
                <div className="text-[16px] leading-[25.6px] text-grey1">
                  Reward Coins
                </div>
              </div>
              {/* <div className="text-[16px] leading-[25.6px] ">0.092</div> 
            </div> */}
          </div>
        </div>
        {/* <div className="relative">
          <div className="m-auto absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] bg-darkGreen1 w-96 px-6  py-1 rounded-full text-[white] flex items-center justify-center gilroy-medium text-sm gap-4">
            <div className="whitespace-nowrap select-none">{`${auth.virtual_acct} | Taj_Pinspay`}</div>
            <img
              src={copy}
              alt="copy-button"
              className="cursor-pointer"
              onClick={handleCopy}
            />
          </div>
          <hr className="border border-dGrey1 my-[24px]" />
        </div> */}
        <div className="flex gap-[12px] flex-col">
          <div className="text-[14px] leading-[22.4px] text-grey1 mt-5">
            Menu
          </div>
          <div className="flex flex-col !gap-2">
            <div
              className="flex gap-3 cursor-pointer group"
              onClick={() => {
                setShowProfile(false);
                navigate("/bills/history ");
              }}
            >
              <MdOutlineHistory className="size-[24px] rotate-180 group-hover:text-darkGreen2 transition-all" />
              <div className="text-[16px] leading-[25.6px] text-grey1 group-hover:text-darkGreen2 transition-all ml-2">
                Transaction History
              </div>
            </div>
            <div
              className="flex gap-3 cursor-pointer group"
              onClick={() => {
                setShowProfile(false);
                navigate("/views/ticket/type");
              }}
            >
              <IoTicketOutline className="size-[24px] rotate-180 group-hover:text-darkGreen2 transition-all" />
              <div className="text-[16px] leading-[25.6px] text-grey1 group-hover:text-darkGreen2 transition-all ml-2">
                Ticket
              </div>
            </div>
            <div
              className="flex gap-3 cursor-pointer group"
              onClick={() => {
                setShowProfile(false);
                navigate("/settings/order-list");
              }}
            >
              <IoReceiptOutline className="size-[24px] rotate-180 group-hover:text-darkGreen2 transition-all" />
              <div className="text-[16px] leading-[25.6px] text-grey1 group-hover:text-darkGreen2 transition-all ml-2">
                Orders
              </div>
            </div>
            <div
              className="flex gap-3 cursor-pointer group"
              onClick={() => {
                setShowProfile(false);
                navigate("/settings/wishlist");
              }}
            >
              <GoHeart className="size-[24px] group-hover:text-darkGreen2 transition-all" />
              <div className="text-[16px] leading-[25.6px] text-grey1 group-hover:text-darkGreen2 transition-all ml-2">
                Wishlist
              </div>
            </div>
            <div
              className="flex gap-3 cursor-pointer group"
              onClick={() => {
                setShowProfile(false);
                navigate("/settings/change-password");
              }}
            >
              <TbPassword className="size-[24px] rotate-180 group-hover:text-darkGreen2 transition-all" />
              <div className="text-[16px] leading-[25.6px] text-grey1 group-hover:text-darkGreen2 transition-all ml-2">
                Change Password
              </div>
            </div>
            <div
              className="flex gap-3 cursor-pointer group"
              onClick={() => {
                setShowProfile(false);
                navigate("/settings");
              }}
            >
              <IoSettingsOutline className="size-[24px] group-hover:text-darkGreen2 transition-all" />
              <div className="text-[16px] leading-[25.6px] text-grey1 group-hover:text-darkGreen2 transition-all ml-2">
                Settings
              </div>
            </div>
          </div>
        </div>
        <hr className="border border-dGrey1 my-[24px]" />
        <div className="flex gap-3 cursor-pointer" onClick={logout}>
          <RiLogoutCircleRLine className="size-[24px] text-red1" />
          <div className="text-[16px] leading-[25.6px] text-red1">Sign Out</div>
        </div>
      </motion.div>
    </div>
  );
};

export default Profile;
