import Search from "../assets/images/search-normal.svg";
import { PiShoppingCartFill } from "react-icons/pi";
import { BiSolidBell } from "react-icons/bi";
import { HiMiniEnvelope } from "react-icons/hi2";
import { RxDividerVertical } from "react-icons/rx";
import { useWishlist } from "../hooks/useWishList";

import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useModal } from "../hooks/useModal";
import { useAuth } from "../hooks/useAuth";
import { useProduct } from "../hooks/useProduct";
import { FaPhoneAlt } from "react-icons/fa";
import Profile from "../components/modals/Profile";
import Line from "../assets/images/Line 1.svg";
import NoUser from "../assets/images/NoUser.svg";
import AnimatedButton from "./AnimatedButton";
import { RiCustomerServiceFill } from "react-icons/ri";
import { toast } from "sonner";
import { motion } from "framer-motion";
import { useCart } from "../hooks/useCart";
import { NavBarLogo, Preloader } from "../all_images";
import { FaRegUser } from "react-icons/fa6";
import { FaUser } from "react-icons/fa";

import "select2"; // Assuming select2 is installed
import "slick-carousel"; // Assuming slick-carousel is installed
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MenuCategories from "./MenuCategories";
import SearchableDropdown from "./SearchableDropdown";
import { allCategories } from "../allArrs";
import axiosInstance from "../api/axios";
import { useLocation } from "react-router-dom";

import { ApiRoutes } from "../tools/apiRoutes";

// import "../assets/css/bootstrap.min.css";

export default function Navbar() {
  const [appInfo, setAppInfo] = useState(null);

  const { pathname } = location;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { openModal } = useModal();
  const { auth, categories, getRandomThreeDigitNumber, setIsLoading } =
    useAuth();
  const { getProductByCategoryAnProductName } = useProduct();
  const { cart } = useCart();
  const [notification, setNotification] = useState(0);
  const [showProfile, setShowProfile] = useState(false);
  const [categorySearchId, setCategorySearchId] = useState(null);
  const [productName, setProductName] = useState("");

  const [isDropdownActive, setDropdownActive] = useState(false);
  const [isScrollHideSm, setScrollHideSm] = useState(false);
  const [isFixedHeader, setFixedHeader] = useState(false);
  const { wishlist } = useWishlist();
  const getInfo = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(ApiRoutes.appInfo);
      if (Number(response.data.status) === 200) {
        setAppInfo(response.data.data);
      } else {
        console.error(response);
      }
    } catch (e) {
      console.error("couldn't create address", e);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const SearchRoutes = [
    /.*\/category\/[^/]+.*/,
    /.*\/category\/[^/]+\/[^/]+.*/,
    /.*\/subcategory\/[^/]+\/[^/]+\/[^/]+.*/,
    /.*\/recommended\/[^/]+.*/,
  ];

  const isDynamicPath = (pathname) => {
    return SearchRoutes.some((pattern) => pattern.test(pathname));
  };

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    // const handleLoad = () => {
    //   $('.preloader').fadeOut();
    // };

    // window.addEventListener('load', handleLoad);

    // setTimeout(() => {
    //   $('.preloader').fadeOut();
    // }, 20);

    // Scroll listener for fixed header
    const handleScroll = () => {
      if (window.scrollY >= 260) {
        setFixedHeader(true);
      } else {
        setFixedHeader(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // window.removeEventListener('load', handleLoad);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [activeItem, setActiveItem] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);
  // const [value, setValue] = useState("All Category");
  const [value, setValue] = useState({ id: 0, name: "All Category" });
  const firstLetterOfFirstName = auth ? auth.firstname.split("")[0] : "";

  const handleSearch = (e) => {
    const value = e.target.value;

    if (value.length > 40) {
      return;
    }
    setProductName(value);
  };

  const submitProductByCat = (pathname) => {
    // setIsLoading(true);
    getProductByCategoryAnProductName(categorySearchId, productName);

    if (!isDynamicPath(pathname)) {
      const params = new URLSearchParams({
        q: productName,
      });
      navigate(`/search?${params.toString()}`);
    }
  };

  // useEffect(() => {
  //   if(productName !== ""){
  //     getProductByCategoryAnProductName(categorySearchId, productName);
  //   }
  // }, [productName]);

  const toggleDropdown = () => {
    setDropdownActive(!isDropdownActive);
    setScrollHideSm(!isScrollHideSm);
  };

  const activeCategories = categories
    .filter((category) => category.status === "active") // Filter active categories
    .map((category) => ({
      ...category,
    }));

  const catName = value.name
    .replace(/ /g, "-")
    .replace(/&/g, "and")
    .toLowerCase();
  const catIDs = value.id;

  const catUrls = `/category/${catName}/${getRandomThreeDigitNumber()}${catIDs}${getRandomThreeDigitNumber()}/`;

  //

  return (
    <>
      {/* <div className="preloader">
        <img src={Preloader} alt="" />
      </div> */}

      <div className="overlay"></div>
      <div className="side-overlay"></div>

      <form action="#" className="search-box">
        <button
          type="button"
          className="search-box__close position-absolute inset-block-start-0 inset-inline-end-0 m-16 w-48 h-48 border border-gray-100 rounded-circle flex-center text-white hover-text-gray-800 hover-bg-white text-2xl transition-1"
        >
          <i className="ph ph-x"></i>
        </button>

        <div className="container">
          <div className="position-relative">
            <input
              type="text"
              className="form-control py-16 px-24 text-xl rounded-pill pe-64"
              placeholder="Search for a product or brand"
              value={productName}
              onChange={handleSearch}
            />
            <button
              onClick={() => submitProductByCat(pathname)}
              className="w-48 h-48 bg-main-600 rounded-circle flex-center text-xl text-white position-absolute top-50 translate-middle-y inset-inline-end-0 me-8"
            >
              <i className="ph ph-magnifying-glass"></i>
            </button>
          </div>
        </div>
      </form>

      <div className="mobile-menu scroll-sm d-lg-none d-block ">
        <button
          type="button"
          className="close-button__ mt-6 !p-2 !bg-gray-300 absolute right-2 top-1 rounded-full size-9"
          onClick={() => {
            document.querySelector(".mobile-menu").classList.remove("active");
            document.querySelector(".side-overlay").classList.remove("show");
            document.querySelector("body").classList.remove("scroll-hide-sm");
          }}
        >
          {" "}
          <i className="ph ph-x"></i>{" "}
        </button>
        <div className="mobile-menu__inner">
          <Link to="/" className="mobile-menu__logo">
            <img src={NavBarLogo} alt="Logo" className="w-[80px] -ml-3 -mt-4" />
          </Link>

          <div className="mobile-menu__menu">
            <ul className="nav-menu flex-align nav-menu--mobile">
              <li className="on-hover-item nav-menu__item has-submenu">
                <Link to="#" className="nav-menu__link">
                  Home
                </Link>
                {/* {activeMenu === 'home' && ( */}
                <ul className="on-hover-dropdown common-dropdown nav-submenu scroll-sm">
                  <li className="common-dropdown__item nav-submenu__item">
                    <Link
                      to="/"
                      className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                    >
                      {" "}
                      About Joatech
                    </Link>
                  </li>
                  <li className="common-dropdown__item nav-submenu__item">
                    <Link
                      to="/"
                      className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                    >
                      {" "}
                      Advertise With Us
                    </Link>
                  </li>
                  <li className="common-dropdown__item nav-submenu__item">
                    <Link
                      to="/"
                      className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                    >
                      {" "}
                      Our Blog
                    </Link>
                  </li>
                  <li className="common-dropdown__item nav-submenu__item">
                    <Link
                      to="/"
                      className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                    >
                      {" "}
                      FAQs
                    </Link>
                  </li>
                </ul>
                {/* )} */}
              </li>
              <li className="on-hover-item nav-menu__item has-submenu">
                <Link to="/bills" className="nav-menu__link">
                  Pay Bills
                </Link>
                <ul className="on-hover-dropdown common-dropdown nav-submenu scroll-sm">
                  <li className="common-dropdown__item nav-submenu__item">
                    <Link
                      to="/airtime"
                      className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                    >
                      {" "}
                      Buy Airtime
                    </Link>
                  </li>
                  <li className="common-dropdown__item nav-submenu__item">
                    <Link
                      to="/physical-Products"
                      className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                    >
                      {" "}
                      Buy Physical Airtime
                    </Link>
                  </li>
                  <li className="common-dropdown__item nav-submenu__item">
                    <Link
                      to="/data"
                      className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                    >
                      {" "}
                      Buy Data
                    </Link>
                  </li>
                  {/* <li className="common-dropdown__item nav-submenu__item">
                    <Link
                      to="/"
                      className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                    >
                      {" "}
                      Other Bills
                    </Link>
                  </li> */}
                </ul>
              </li>
              <li className="nav-menu__item">
                <Link to="/" className="nav-menu__link">
                  Sell On Joatech
                </Link>
              </li>
              <li className="nav-menu__item">
                <Link to="/" className="nav-menu__link">
                  Contact Us
                </Link>
              </li>

              <li className="nav-menu__item">
                <Link
                  to="#"
                  className="nav-menu__link"
                  onClick={() => openModal("signIn")}
                >
                  SignIn / SignUp
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <header className="header-middle  bg-color-one border-bottom border-gray-100 px-20 sticky top-0 left-0 z-50 !bg-[#DEEDDD]">
        <div className="container_ _container-lg pr-0">
          <nav className="header-inner_ flex-between row">
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6 _bg-green-500">
              <div className="logo flex justify-center_ items-center -mb-3 -mt-3">
                <Link to="/" className="link">
                  <div className="row">
                    <div className="col-5 pl-5">
                      <img
                        src={NavBarLogo}
                        alt="Logo"
                        className="lg:-mt-[10px] sm:mt-0 md:max-w-[62px] xs:max-w-[50px]"
                      />
                    </div>

                    <div className="col-7 flex justify-center items-center">
                      <p className="-ml-2 gilroy-heavy md:text-[23px] xs:text-[20px] md:-mt-2 xs:mt-0 text-gray-900 ">
                        Joatech
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-1 d-md-block d-none _bg-yellow-500">
              <form
                action="#"
                className="flex-align_ flex-wrap form-location-wrapper"
              >
                <div className="search-category d-flex h-48 select-border-end-0 radius-end-0 search-form d-md-flex d-none row p-0 lg:-mt-3 md:mt-0">
                  <div className="col-3 p-0">
                    <SearchableDropdown
                      options={activeCategories}
                      label="name"
                      id="id"
                      selectedVal={value.name}
                      handleChange={(val) => {
                        const selectedCategory = activeCategories.find(
                          (category) => category.name === val
                        );
                        if (selectedCategory) {
                          setValue({
                            id: selectedCategory.id,
                            name: selectedCategory.name,
                          });
                          setCategorySearchId(selectedCategory.id);
                        }
                      }}
                    />
                  </div>

                  <div className="search-form__wrapper position-relative col-9 p-0">
                    <input
                      type="text"
                      className="search-form__input custom-font-normal common-input py-13 ps-16 pe-18 rounded-end-pill pe-44"
                      placeholder="Search for a product or brand"
                      value={productName}
                      // onChange={(e) => setProductName(e.target.value)}
                      onChange={handleSearch}
                    />

                    {/* <button type="button" onClick={() => navigate(catUrls)} className="w-32 h-32 bg-main-600 rounded-circle flex-center text-xl text-white position-absolute top-50 translate-middle-y inset-inline-end-0 me-8"><i className="ph ph-magnifying-glass"></i></button> */}
                    <button
                      type="button"
                      onClick={() => submitProductByCat(pathname)}
                      className="w-32 h-32 bg-main-600 rounded-circle flex-center text-xl text-white position-absolute top-50 translate-middle-y inset-inline-end-0 me-8"
                    >
                      <i className="ph ph-magnifying-glass"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-3 pl-0 col-6 flex justify-end lg:pr-[20px] md:pr-[40px] xs:pr-[30px] _bg-red-500">
              <div className="header-right flex-align d-lg-block d-none_">
                <div className="flex-align flex-wrap gap-18">
                  <button
                    type="button"
                    className="search-icon flex-align d-md-none d-flex gap-4 item-hover"
                  >
                    <span className="text-2xl text-gray-700 d-flex position-relative item-hover__text">
                      <i className="ph ph-magnifying-glass"></i>
                    </span>
                  </button>

                  <Link
                    to="/settings/wishlist"
                    className="flex-align gap-4 item-hover"
                  >
                    <span className="text-2xl text-gray-700 d-flex position-relative me-6 mt-6_ item-hover__text">
                      <i className="ph ph-heart"></i>
                      {wishlist && wishlist.length > 0 && (
                        <span className="w-16 h-16 flex-center rounded-circle bg-[red] text-white text-[10px] position-absolute top-n6 end-n4">
                          {wishlist.length > 9 ? "9+" : wishlist.length}
                        </span>
                      )}
                    </span>
                    <span className="text-sm text-gray-800 font-light item-hover__text d-none d-lg-flex -ml-1">
                      Wishlist
                    </span>
                  </Link>

                  {/* <Link to="/cart" className="relative">
                        <PiShoppingCartFill className="size-[24px] cursor-pointer" />
                        {notification <= 0  && (
                          <span className="w-16 h-16 flex-center rounded-circle bg-main-600 text-white text-[10px] position-absolute top-n6 end-n4">
                            {notification > 9 ? '9+': notification}
                          </span>
                        )}
                      </Link> */}

                  <div className="navbar-right flex items-center ml-auto text-gray-700 gap-[2px] md:gap-[16px]">
                    <Link to="/cart" className="relative">
                      <PiShoppingCartFill className="size-[24px] cursor-pointer" />

                      {cart.length > 0 && (
                        <div className="w-16 h-16 flex-center rounded-circle bg-[red] text-white text-[10px] position-absolute top-n6 end-n4">
                          {cart > 9 ? "9+" : cart.length}
                        </div>
                      )}
                    </Link>
                    <span className="-ml-3 mr-2 max-lg:hidden">Cart</span>

                    {auth && (
                      <>
                        {/* <BiSolidBell className="size-[24px] cursor-pointer" /> */}
                        {/* <HiMiniEnvelope className="size-[24px] cursor-pointer" /> */}
                      </>
                    )}
                  </div>

                  {auth ? (
                    <div
                      className="size-[24px] md:size-[41px] cursor-pointer border-2 border-grey1 text-grey1 rounded-full flex items-center justify-center text-lg"
                      onClick={() => {
                        setShowProfile((prev) => !prev);
                      }}
                    >
                      {/* {firstLetterOfFirstName} */}
                      {/* <span className="ph ph-user custom-font-bold"></span> */}
                      {/* <FaRegUser className="size-5" /> */}
                      <FaUser />
                    </div>
                  ) : (
                    <h3
                      className="text-[15px] cursor-pointer text-darkGreen1 leading-[28px] tracking-[-0.2px] -ml-2"
                      onClick={() => {
                        openModal("signIn");
                      }}
                    >
                      Log in
                    </h3>
                  )}
                  {showProfile && auth && (
                    <Profile setShowProfile={setShowProfile} />
                  )}

                  {/* <Link to="/" className="flex-align gap-4 item-hover">
                        <span className="text-2xl text-gray-700 d-flex position-relative me-6 mt-6 item-hover__text">
                          <i className="ph ph-shopping-cart-simple"></i>
                          <span className="w-16 h-16 flex-center rounded-circle bg-main-600 text-white text-[10px] position-absolute top-n6 end-n4">2</span>
                        </span>
                        <span className="text-sm text-gray-800 item-hover__text d-none d-lg-flex">Cart</span>
                      </Link> */}
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>

      <header className="bg-white_ border-bottom border-gray-100 sticky top-16 left-0 z-40 !bg-white">
        {/*header */}
        <div className="container_ container-lg">
          <nav className="header-inner d-flex justify-content-between gap-8">
            <div className="flex-align menu-category-wrapper">
              <MenuCategories NavBarLogo={NavBarLogo} />

              <div className="header-menu d-lg-block d-none">
                <ul className="nav-menu flex-align ">
                  <li className="on-hover-item nav-menu__item has-submenu">
                    <Link to="#" className="nav-menu__link">
                      Home
                    </Link>
                    <ul className="on-hover-dropdown common-dropdown">
                      <li className="common-dropdown__item nav-submenu__item">
                        <Link
                          to="/"
                          className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                        >
                          {" "}
                          About Joatech
                        </Link>
                      </li>
                      <li className="common-dropdown__item nav-submenu__item">
                        <Link
                          to="/"
                          className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                        >
                          {" "}
                          Advertise With Us
                        </Link>
                      </li>
                      <li className="common-dropdown__item nav-submenu__item">
                        <Link
                          to="/"
                          className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                        >
                          {" "}
                          Our Blog
                        </Link>
                      </li>
                      <li className="common-dropdown__item nav-submenu__item">
                        <Link
                          to="/"
                          className="common-dropdown__link nav-submenu__link hover-bg-neutral-100"
                        >
                          {" "}
                          FAQs
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-menu__item">
                    <Link to="contact.html" className="nav-menu__link">
                      Sell on Joatech
                    </Link>
                  </li>
                  <li className="nav-menu__item">
                    <Link to="contact.html" className="nav-menu__link">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="header-right flex-align">
              <div className="me-0 d-lg-none_ d-block_ relative left-2">
                <div className="flex-align flex-wrap gap-12">
                  <a
                    href={`tel:${appInfo?.phone_number}`}
                    className="bg-main-600 text-white p-12 h-100 hover-bg-main-800 flex-align gap-8 text-md d-lg-flex d-none"
                  >
                    <div className="d-flex text-32">
                      <i className="ph ph-phone-call"></i>
                    </div>
                    {appInfo?.phone_number || "Not Available"}
                  </a>
                </div>
              </div>
              <button
                type="button"
                className="toggle-mobileMenu_ d-lg-none ms-3n text-gray-800 text-4xl d-flex"
                onClick={() => {
                  document
                    .querySelector(".mobile-menu")
                    .classList.add("active");
                  document.querySelector(".side-overlay").classList.add("show");
                  document
                    .querySelector("body")
                    .classList.add("scroll-hide-sm");
                }}
              >
                {" "}
                <i className="ph ph-list"></i>{" "}
              </button>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}
