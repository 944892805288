import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import $ from "jquery";
function MenuCategories({ NavBarLogo }) {
  const { auth, categories, isLoading, getRandomThreeDigitNumber } = useAuth();
  const activeCategories = categories
    .filter((category) => category.status === "active") // Filter active categories
    .map((category) => ({
      ...category,
      sub_categories: category.sub_categories.filter(
        (sub) => sub.status === "active"
      ), // Filter active subcategories
    }));

  const handleDropdownClick = (e) => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 992) {
      const $target = $(e.currentTarget);
      const $submenu = $target.siblings(".submenus-submenu");

      if ($target.hasClass("active")) {
        $target.removeClass("active").siblings(".submenus-submenu").slideUp();
      } else {
        $(".responsive-dropdown .has-submenus-submenu.active")
          .removeClass("active")
          .siblings(".submenus-submenu")
          .slideUp();

        $target.addClass("active");
        $submenu.slideDown();
      }
    }
  };
  return (
    // onClick={() => {
    //   console.log("closing from react");
    //   document.querySelector(".mobile-menu").classList.remove("active");
    //   document.querySelector(".side-overlay").classList.remove("show");
    //   document.querySelector("body").classList.remove("scroll-hide-sm");
    // }}
    <div className="category on-hover-item">
      <button
        type="button"
        className="category__button_ text-[15px] flex-align gap-8 fw-medium p-16 border-end border-start_ border-gray-100 text-heading -ml-3"
        onClick={() => {
          // console.log("adding from react");
          document
            .querySelector(".responsive-dropdown")
            ?.classList.add("active");
          document.querySelector(".side-overlay")?.classList.add("show");
          document.body.classList.add("scroll-hide-sm");
        }}
      >
        <span className="icon text-2xl d-xs-flex d-none">
          <i className="ph ph-dots-nine"></i>
        </span>
        <span className="d-sm-flex d-none">All</span> Categories
        <span className="arrow-icon text-xl d-flex">
          <i className="ph ph-caret-down"></i>
        </span>
      </button>

      <div className="responsive-dropdown on-hover-dropdown common-dropdown nav-submenu p-0 submenus-submenu-wrapper pt-5 pb-6 ">
        <button
          type="button"
          className="close-responsive-dropdown__ rounded-circle text-xl position-absolute inset-inline-end-0 inset-block-start-0 !mt-2 me-8 d-lg-none d-flex border !p-2 !bg-gray-300"
          onClick={() => {
            document
              .querySelector(".responsive-dropdown")
              ?.classList.remove("active");
            document.querySelector(".side-overlay")?.classList.remove("show");
            document.body.classList.remove("scroll-hide-sm");
          }}
        >
          {" "}
          <i className="ph ph-x"></i>{" "}
        </button>

        <div className="logo px-16 d-lg-none d-block">
          <Link to="/" className="link">
            <img src={NavBarLogo} alt="Logo" className="w-[68px]" />
          </Link>
        </div>

        {isLoading ? (
          <div className="text-[13px] p-5">Loading...</div>
        ) : (
          <ul className="scroll-sm p-0 py-8 w-300 max-h-400 overflow-y-auto">
            {activeCategories.length !== 0 &&
              activeCategories.map((category, index) => {
                let catIcon = "";

                const catUrl = category.name
                  .replace(/ /g, "-")
                  .replace(/&/g, "and")
                  .toLowerCase();
                const catID = category.id;
                let cat_names = category.name;

                if (category.name.toLowerCase().includes("electronics")) {
                  catIcon = "monitor";
                } else if (category.name.toLowerCase().includes("phone")) {
                  catIcon = "device-mobile";
                } else if (category.name.toLowerCase().includes("fashion")) {
                  catIcon = "dress";
                  cat_names = "Joatech " + cat_names;
                } else if (
                  category.name.toLowerCase().includes("computing") ||
                  category.name.toLowerCase().includes("computer")
                ) {
                  catIcon = "laptop";
                } else if (
                  category.name.toLowerCase().includes("toys") ||
                  category.name.toLowerCase().includes("kid")
                ) {
                  catIcon = "baby-carriage";
                } else if (category.name.toLowerCase().includes("cosmetics")) {
                  catIcon = "hair-dryer";
                } else if (category.name.toLowerCase().includes("kitchen")) {
                  catIcon = "cooking-pot";
                } else if (category.name.toLowerCase().includes("book")) {
                  catIcon = "books";
                } else if (category.name.toLowerCase().includes("beauty")) {
                  catIcon = "baby";
                } else if (category.name.toLowerCase().includes("gaming")) {
                  catIcon = "game-controller";
                }

                return (
                  <li
                    className="has-submenus-submenu"
                    key={`${category.id}${index}`}
                  >
                    <button
                      className="text-gray-800 text-[14px] py-8 px-16 flex-align gap-8 rounded-0 justify-between  w-full"
                      onClick={(e) => {
                        handleDropdownClick(e);
                      }}
                    >
                      <span className="text-xl d-flex">
                        <i className={`ph ph-${catIcon}`}></i>
                      </span>
                      <span className="capitalize">{cat_names}</span>
                      <span className="icon text-md d-flex ms-auto">
                        <i className="ph ph-caret-right"></i>
                      </span>
                    </button>

                    <div className="submenus-submenu py-16 lg:bg-[#f7f7f7] md:bg-transparent border-gray-100 max-md:bg-transparent">
                      <div className="flex flex-between pr-3">
                        <h6 className="px-16 text-[15px] submenus-submenu__title capitalize">
                          {cat_names}
                        </h6>
                        <span className="text-xl d-flex_ d-lg-flex d-none">
                          <i className={`ph ph-${catIcon}`}></i>
                        </span>
                      </div>
                      <ul className="submenus-submenu__list max-h-300 overflow-y-auto scroll-sm">
                        <li className="-mt-2">
                          <Link
                            to={`/category/${catUrl}/${getRandomThreeDigitNumber()}${catID}${getRandomThreeDigitNumber()}/`}
                            className="capitalize "
                            onClick={() => {
                              document
                                .querySelector(".responsive-dropdown")
                                ?.classList.remove("active");
                              document
                                .querySelector(".side-overlay")
                                ?.classList.remove("show");
                              document.body.classList.remove("scroll-hide-sm");
                            }}
                          >
                            {cat_names}
                          </Link>
                        </li>
                        {category.sub_categories.length !== 0 &&
                          category.sub_categories.map((subcat, index1) => {
                            const subCatUrl = subcat.name
                              .replace(/ /g, "-")
                              .replace(/&/g, "and")
                              .toLowerCase();
                            const subCatID = subcat.id;
                            return (
                              <li key={`${subcat.id}-${index1}`}>
                                <Link
                                  to={`/subcategory/${catUrl}/${subCatUrl}/${getRandomThreeDigitNumber()}${subCatID}${getRandomThreeDigitNumber()}/`}
                                  className="capitalize "
                                  onClick={() => {
                                    document
                                      .querySelector(".responsive-dropdown")
                                      ?.classList.remove("active");
                                    document
                                      .querySelector(".side-overlay")
                                      ?.classList.remove("show");
                                    document.body.classList.remove(
                                      "scroll-hide-sm"
                                    );
                                  }}
                                >
                                  {subcat.name}
                                </Link>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </li>
                );
              })}

            {/* <li className="has-submenus-submenu">
                            <Link className="text-gray-800 text-[15px] py-12 px-16 flex-align gap-8 rounded-0">
                                <span className="text-xl d-flex"><i className="ph ph-carrot"></i></span>
                                <span>Vegetables &amp; Fruit</span>
                                <span className="icon text-md d-flex ms-auto"><i className="ph ph-caret-right"></i></span>
                            </Link>

                            <div className="submenus-submenu py-16">
                                <h6 className="text-lg px-16 submenus-submenu__title">Vegetables &amp; Fruit</h6>
                                <ul className="submenus-submenu__list max-h-300 overflow-y-auto scroll-sm">
                                    <li>
                                        <Link to="/">Potato &amp; Tomato</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Cucumber &amp; Capsicum</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Leafy Vegetables</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Root Vegetables</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Beans &amp; Okra</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Cabbage &amp; Cauliflower</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Gourd &amp; Drumstick</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Specialty</Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className="has-submenus-submenu">
                            <Link to="#" className="text-gray-800 text-[15px] py-12 px-16 flex-align gap-8 rounded-0">
                                <span className="text-xl d-flex"><i className="ph ph-brandy"></i></span>
                                <span>Beverages</span>
                                <span className="icon text-md d-flex ms-auto"><i className="ph ph-caret-right"></i></span>
                            </Link>
                            <div className="submenus-submenu py-16">
                                <h6 className="text-lg px-16 submenus-submenu__title">Beverages</h6>
                                <ul className="submenus-submenu__list max-h-300 overflow-y-auto scroll-sm">
                                    <li>
                                        <Link to="/">Soda & Cocktail Mix </Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Sports & Energy Drinks</Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Non Alcoholic Drinks</Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Packaged Water </Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Spring Water</Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Flavoured Water    </Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className="has-submenus-submenu">
                            <Link to="#" className="text-gray-800 text-[15px] py-12 px-16 flex-align gap-8 rounded-0">
                                <span className="text-xl d-flex"><i className="ph ph-brandy"></i></span>
                                <span>Meats & Seafood</span>
                                <span className="icon text-md d-flex ms-auto"><i className="ph ph-caret-right"></i></span>
                            </Link>
                            <div className="submenus-submenu py-16">
                                <h6 className="text-lg px-16 submenus-submenu__title">Meats & Seafood</h6>
                                <ul className="submenus-submenu__list max-h-300 overflow-y-auto scroll-sm">
                                    <li>
                                        <Link to="/"> Fresh Meat </Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Frozen Meat</Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Marinated Meat</Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Fresh & Frozen Meat</Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className="has-submenus-submenu">
                            <Link to="#" className="text-gray-800 text-[15px] py-12 px-16 flex-align gap-8 rounded-0">
                                <span className="text-xl d-flex"><i className="ph ph-brandy"></i></span>
                                <span>Breakfast & Dairy</span>
                                <span className="icon text-md d-flex ms-auto"><i className="ph ph-caret-right"></i></span>
                            </Link>
                            <div className="submenus-submenu py-16">
                                <h6 className="text-lg px-16 submenus-submenu__title">Breakfast & Dairy</h6>
                                <ul className="submenus-submenu__list max-h-300 overflow-y-auto scroll-sm">
                                    <li>
                                        <Link to="/"> Oats & Porridge</Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Kids Cereal</Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Muesli</Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Flakes</Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Granola & Cereal Bars</Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Instant Noodles</Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className="has-submenus-submenu">
                            <Link to="#" className="text-gray-800 text-[15px] py-12 px-16 flex-align gap-8 rounded-0">
                                <span className="text-xl d-flex"><i className="ph ph-brandy"></i></span>
                                <span>Frozen Foods</span>
                                <span className="icon text-md d-flex ms-auto"><i className="ph ph-caret-right"></i></span>
                            </Link>
                            <div className="submenus-submenu py-16">
                                <h6 className="text-lg px-16 submenus-submenu__title">Frozen Foods</h6>
                                <ul className="submenus-submenu__list max-h-300 overflow-y-auto scroll-sm">
                                    <li>
                                        <Link to="/"> Instant Noodles </Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Hakka Noodles</Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Cup Noodles</Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Vermicelli</Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Instant Pasta</Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className="has-submenus-submenu">
                            <Link to="#" className="text-gray-800 text-[15px] py-12 px-16 flex-align gap-8 rounded-0">
                                <span className="text-xl d-flex"><i className="ph ph-brandy"></i></span>
                                <span>Biscuits & Snacks</span>
                                <span className="icon text-md d-flex ms-auto"><i className="ph ph-caret-right"></i></span>
                            </Link>
                            <div className="submenus-submenu py-16">
                                <h6 className="text-lg px-16 submenus-submenu__title">Biscuits & Snacks</h6>
                                <ul className="submenus-submenu__list max-h-300 overflow-y-auto scroll-sm">
                                    <li>
                                        <Link to="/"> Salted Biscuits </Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Marie, Health, Digestive</Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Cream Biscuits & Wafers </Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Glucose & Milk biscuits</Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Cookies</Link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className="has-submenus-submenu">
                            <Link to="#" className="text-gray-800 text-[15px] py-12 px-16 flex-align gap-8 rounded-0">
                                <span className="text-xl d-flex"><i className="ph ph-brandy"></i></span>
                                <span>Grocery & Staples</span>
                                <span className="icon text-md d-flex ms-auto"><i className="ph ph-caret-right"></i></span>
                            </Link>
                            <div className="submenus-submenu py-16">
                                <h6 className="text-lg px-16 submenus-submenu__title">Grocery & Staples</h6>
                                <ul className="submenus-submenu__list max-h-300 overflow-y-auto scroll-sm">
                                    <li>
                                        <Link to="/"> Lemon, Ginger & Garlic </Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Indian & Exotic Herbs</Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Orangic Vegetables</Link>
                                    </li>
                                    <li>
                                        <Link to="/">Orangic Fruits </Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Orangic Dry Fruits</Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Orangic Dals & pulses</Link>
                                    </li>
                                    <li>
                                        <Link to="/"> Orangic Millet & Flours</Link>
                                    </li>
                                </ul>
                            </div>
                        </li> */}
          </ul>
        )}
      </div>
    </div>
  );
}

export default MenuCategories;
