import "../../components/component.css";
import ProductCard from "../../components/ProductCard";
import { useProduct } from "../../hooks/useProduct";
import NotFound from "../../components/NotFound";
import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ProductCard2 from "../../components/ProductCard2";
import { useAuth } from "../../hooks/useAuth";

export default function SearchProducts({ filteredProducts = [] }) {
  const { products } = useProduct();
  const { isLoading } = useAuth();

  // console.log("isLoading");
  // console.log(isLoading);

  if (products.length > 0) {
    filteredProducts = products;
  }

  // const filteredData = !category ? products : products.filter((item)=>item.category === categoryMatch
  // );
  const productsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  const startIndex = (currentPage - 1) * productsPerPage;

  const endIndex = startIndex + productsPerPage;

  const displayedData = filteredProducts.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const getPaginationButtons = () => {
    const buttons = [];
    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(totalPages, startPage + 2);

    if (endPage - startPage < 2) {
      startPage = Math.max(1, endPage - 2);
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          className={`join-item btn ${
            i === currentPage
              ? "border-2 border-darkGreen1"
              : "border-[#E0E0E0]"
          }`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  return isLoading ? (
    // <div className="fixed w-screen bg-[white] h-screen flex items-center justify-center">
    //     <span className="loading loading-dots w-[10vw] min-w-[90px] text-darkGreen1"></span>
    //   </div>

    <div className="w-screen pt-[50px] pb-[100px] bg-[white] items-center justify-center flex-align flex-0">
      <p>
        <span className="loading loading-dots loading-lg"></span>
      </p>
      <p className="text-[13px] text-[#888]">Loading...</p>
    </div>
  ) : (
    <div className="flex flex-col items-center mb-[60px] lg:mb-[0px]">
      <AnimatePresence mode="wait">
        <motion.div
          key={currentPage}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex flex-wrap gap-[24px] mb-[32px] lg:mb-[56px]">
            {displayedData &&
              displayedData.length > 0 &&
              displayedData.map((card, i) => (
                <ProductCard key={i} product={card} />
              ))}
          </div>

          {displayedData && displayedData.length <= 0 && (
            <div className="bg-red-400_ items-center justify-between">
              <NotFound />
            </div>
          )}
        </motion.div>
      </AnimatePresence>

      {displayedData && displayedData.length > 0 && (
        <div className="flex justify-center">
          <div className="join">
            <button
              className="join-item btn border-[#E0E0E0] disabled:!bg-gray-200 disabled:!text-gray-400"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              &lt;
            </button>
            {getPaginationButtons()}
            <button
              className="join-item btn border-[#E0E0E0] disabled:!bg-gray-200 disabled:!text-gray-400"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              &gt;
            </button>
          </div>
        </div>
      )}
      {/* <div> {console.log("products showing:", products)}</div>
      <div> {console.log("Displayed data", displayedData)}</div> */}
    </div>
  );
}
