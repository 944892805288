export const ApiRoutes = {
  addToCartMultiple: "users/cart/add/multiple",
  clearCart: "users/cart/clear",
  getCategories: "users/categories",
  getCheckoutSummary: "users/checkout/summary",
  getAddress: "users/address",
  deleteAddress: "users/address/delete",
  makeAddressDefault: "users/address/default",
  editAddress: "users/address/edit",
  createAddress: "users/address/create",
  getStoreById: "users/store/show",
  searchBills: "bills/search",
  discoLookup: "bills/discolookup",
  discoVend: "bills/disco",
  discountGroup: "bills/discountgroup",
  getCommissions: "bills/commissiongroup",
  physicalVend: "bills/buyphysicalproduct",
  physicalHistory: "bills/physicalproducthistory",
  checkPinspayStatus: "users/pinspay/status",
  //review
  allReviews: "users/reviews",
  getReviewsById: "users/reviews/show",
  createReviews: "users/reviews/create",
  //ticket
  getAllTicket: "users/tickets",
  getTicketById: "users/tickets/show",
  AddTicket: "users/tickets",
  messageToTicket: "/users/tickets/update/status",
  updateStatusTicket: "/users/tickets/update/status",
  addMessageToTicket: "/users/tickets/messages",

  //Home page elements
  appInfo: "/app-info",
  Sliders: "/get-sliders",

  //transaction history
  transactionHistory: "users/wallethistory",

  //api Routes

  create2fa: "/users/create2fa",
  verify2fa: "/users/verify2fa",
  activate2fa: "/users/activate2fa",

  //get network
  getnetwork: "/getnetwork",
};
