// axiosInstance.js
import axios from "axios";
import { API_ROUTES } from "../constants/server";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { logoutUser } from "../context/AuthContext";

const axiosInstance = axios.create({
  baseURL: API_ROUTES.SERVER_ENDPOINT,

  headers: {
    "Content-Type": "application/json",
  },
});

const encryptData = (data) => {
  const secretKey = import.meta.env.VITE_ENCRYPTION_KEY;
  const iv = CryptoJS.enc.Hex.parse(import.meta.env.VITE_IV_KEY); // 32 hex characters
  console.log("data", data);
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    CryptoJS.enc.Utf8.parse(secretKey),
    {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    }
  );

  return encrypted.toString();
};

axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get("token");

    if (token) {
      config.headers.logintoken = `${token}`;
    }

    const isEncryptionEnabled =
      import.meta.env.VITE_ENCRYPTION_ENABLED === "true";

    if (
      config.method === "post" &&
      isEncryptionEnabled &&
      config.data &&
      !config.data._isEncrypted
    ) {
      const encryptedData = encryptData(config.data);

      config.data = { request_data: encryptedData, _isEncrypted: true };
    }

    return config;
  },

  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (
      response.data.status?.toString() === "302" ||
      response.data.message === "Authentication Failed" ||
      response.data.message == "Expired Token... Please Log in"
    ) {
      logoutUser(); // Call the logout utility

      return Promise.reject({
        response: {
          status: 300, // or any other custom status you prefer
          data: response.data,
        },
      });
    }

    return response;
  },

  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
